import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const baseClasses = 'transition-all duration-1 ease-in-out justify-start box-content flex flex-col';

const SidePanel = ({
  side,
  className,
  activeTabIndex: activeTabIndexProp = null,
  tabs,
  panelOpen,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(activeTabIndexProp);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Detectar mobile usando el userAgent
      const isMobileDevice = /(Mobi|Android|iPhone|iPad|iPod)/.test(navigator.userAgent);
      setIsMobile(isMobileDevice);
    };

    // Llamar una vez al montar el componente
    handleResize();

    // Suscribirnos al evento resize
    window.addEventListener('resize', handleResize);

    // Desuscribirnos al desmontar
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (activeTabIndexProp !== null) {
      setActiveTabIndex(activeTabIndexProp);
    }
  }, [activeTabIndexProp]);

  const style = {
    maxWidth: '100%',
    width: '100%',
    position: 'absolute',
    bottom: isMobile ? '5vh' : '3vh',
    height: isMobile ? '135px' : '118px',
    background: 'rgba(0, 0, 0, 0.01)',
    zIndex: 200,
    visibility: panelOpen ? 'visible' : 'hidden',
    transition: 'visibility 0.1s, opacity 0.1s linear',
    opacity: panelOpen ? 1 : 0,
  };

  return (
    <div
      className={classnames(className, baseClasses)}
      style={style}
    >
      {tabs.map((tab, tabIndex) => {
        if (tabIndex === activeTabIndex) {
          return <tab.content key={tabIndex} />;
        }
        return null;
      })}
    </div>
  );
};

SidePanel.propTypes = {
  side: PropTypes.oneOf(['left', 'right']).isRequired,
  className: PropTypes.string,
  activeTabIndex: PropTypes.number,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.func.isRequired,
    })
  ).isRequired,
  panelOpen: PropTypes.bool.isRequired, // Estado gestionado externamente
};

export default SidePanel;
