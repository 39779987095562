import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Thumbnail from '../Thumbnail';
import ThumbnailTracked from '../ThumbnailTracked';
import * as Types from '../../types';

const ThumbnailList = ({
  thumbnails,
  onThumbnailClick,
  onThumbnailDoubleClick,
  onClickUntrack,
  activeDisplaySetInstanceUIDs = [],
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobileDevice = /(Mobi|Android|iPhone|iPad|iPod)/.test(navigator.userAgent);
      setIsMobile(isMobileDevice);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      id="ohif-thumbnail-list"
      className={
        isMobile
          ? 'ohif-scrollbar study-min-height invisible-scrollbar flex flex-row py-5'
          : 'study-min-height ohif-scrollbar2 scrollbar-thin scrollbar-thumb-[#8e9da1] scrollbar-track-transparent flex overflow-y-hidden overflow-x-scroll py-5'
      }
    >
      <div className="scroll-content flex flex-row">
        {thumbnails.map(
          ({
            displaySetInstanceUID,
            description,
            dragData,
            seriesNumber,
            numInstances,
            loadingProgress,
            modality,
            componentType,
            seriesDate,
            countIcon,
            isTracked,
            canReject,
            onReject,
            imageSrc,
            messages,
            imageAltText,
            isHydratedForDerivedDisplaySet,
          }) => {
            const isActive = activeDisplaySetInstanceUIDs.includes(displaySetInstanceUID);
            switch (componentType) {
              case 'thumbnail':
                return (
                  <Thumbnail
                    key={displaySetInstanceUID}
                    displaySetInstanceUID={displaySetInstanceUID}
                    dragData={dragData}
                    description={description}
                    seriesNumber={seriesNumber}
                    numInstances={numInstances}
                    countIcon={countIcon}
                    imageSrc={imageSrc}
                    imageAltText={imageAltText}
                    messages={messages}
                    isActive={isActive}
                    onClick={() => onThumbnailClick(displaySetInstanceUID)}
                    onDoubleClick={() => onThumbnailDoubleClick(displaySetInstanceUID)}
                  />
                );
              case 'thumbnailTracked':
                return (
                  <ThumbnailTracked
                    key={displaySetInstanceUID}
                    displaySetInstanceUID={displaySetInstanceUID}
                    dragData={dragData}
                    description={description}
                    seriesNumber={seriesNumber}
                    numInstances={numInstances}
                    loadingProgress={loadingProgress}
                    countIcon={countIcon}
                    imageSrc={imageSrc}
                    imageAltText={imageAltText}
                    messages={messages}
                    isTracked={isTracked}
                    isActive={isActive}
                    onClick={() => onThumbnailClick(displaySetInstanceUID)}
                    onDoubleClick={() => onThumbnailDoubleClick(displaySetInstanceUID)}
                    onClickUntrack={() => onClickUntrack(displaySetInstanceUID)}
                  />
                );
              default:
                return null;
            }
          }
        )}
      </div>
    </div>
  );
};

ThumbnailList.propTypes = {
  thumbnails: PropTypes.arrayOf(
    PropTypes.shape({
      displaySetInstanceUID: PropTypes.string.isRequired,
      imageSrc: PropTypes.string,
      imageAltText: PropTypes.string,
      seriesDate: PropTypes.string,
      seriesNumber: Types.StringNumber,
      numInstances: PropTypes.number,
      description: PropTypes.string,
      componentType: Types.ThumbnailType.isRequired,
      isTracked: PropTypes.bool,
      dragData: PropTypes.shape({
        type: PropTypes.string.isRequired,
      }),
    })
  ),
  activeDisplaySetInstanceUIDs: PropTypes.arrayOf(PropTypes.string),
  onThumbnailClick: PropTypes.func.isRequired,
  onThumbnailDoubleClick: PropTypes.func.isRequired,
  onClickUntrack: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

export default ThumbnailList;
