import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import NavBar from '../NavBar';
import Svg from '../Svg';
import Icon from '../Icon';

function Header({
  children,
  menuOptions,
  isReturnEnabled = true,
  onClickReturnButton,
  isSticky = false,
  WhiteLabeling,
  ...props
}) {
  const { t } = useTranslation('Header');
  const [isMobile, setIsMobile] = useState(false);
  const [studyInstanceUID, setStudyInstanceUID] = useState(null);
  const [pacienteID, setPacienteID] = useState(null);
  const [apiData, setApiData] = useState(null);
  const [alphabeticName, setAlphabeticName] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pacienteData, setPacienteData] = useState(null);
  const [estudiosData, setEstudiosData] = useState([]);
  const [hoveredTab, setHoveredTab] = useState(null);
  const [selectedTab, setSelectedTab] = useState('Imágenes');

  const tabs = [
    { label: 'Estudios', href: '#' },
    { label: 'Imágenes', href: '#' },
  ];

  const linkURL = studyInstanceUID
    ? `https://sys.diagnosticolomas.com/generate-pdf-visor/${studyInstanceUID}`
    : '#';

  const formatDate = dateString => {
    if (!dateString) {
      return 'Fecha no disponible';
    }
    const datePart = dateString.split('T')[0];
    const [year, month, day] = datePart.split('-');
    if (!year || !month || !day) {
      return 'Fecha no disponible';
    }
    return `${day}/${month}/${year}`;
  };

  const handleStudyClick = uid => {
    const url = new URL(window.location.href);
    url.searchParams.set('StudyInstanceUIDs', uid);
    window.location.href = url.toString();
  };

  const handleShare = async uid => {
    const url = new URL(window.location.href);
    url.searchParams.set('StudyInstanceUIDs', uid);
    url.searchParams.set('paciente', '');
    const shareURL = url.toString();

    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Estudio',
          text: 'Revisa este estudio:',
          url: shareURL,
        });
      } catch (err) {
        console.error('Error al compartir:', err);
      }
    } else {
      try {
        await navigator.clipboard.writeText(shareURL);
        alert('Enlace copiado al portapapeles');
      } catch (err) {
        console.error('Error copiando enlace:', err);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(/(Mobi|Android|iPhone|iPad|iPod)/.test(navigator.userAgent));
    };

    const urlParams = new URLSearchParams(window.location.search);
    const uid = urlParams.get('StudyInstanceUIDs');
    const paciente = urlParams.get('paciente');

    if (uid) {
      setStudyInstanceUID(uid);
    }
    if (paciente) {
      setPacienteID(paciente);
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchInterpretacion = async () => {
      if (!studyInstanceUID) {
        return;
      }
      try {
        const response = await fetch(
          `https://app.laughing-grothendieck.74-208-159-220.plesk.page/interpretacion`
        );
        if (response.ok) {
          const data = await response.json();
          const matchingRow = data.rows?.find(row => row.uid === studyInstanceUID);
          setApiData(matchingRow ? { interpretacion: matchingRow.interpretacion_medica_si } : null);
        }
      } catch (error) {
        console.error('Error fetching interpretacion:', error);
        setApiData(null);
      }
    };
    fetchInterpretacion();
  }, [studyInstanceUID]);

  useEffect(() => {
    const fetchStudyData = async () => {
      if (!studyInstanceUID) {
        setAlphabeticName(null);
        return;
      }
      try {
        const response = await fetch(
          `https://app.laughing-grothendieck.74-208-159-220.plesk.page/study`
        );
        if (!response.ok) {
          throw new Error('Error fetching study data');
        }
        const data = await response.json();
        const matchingRow = data.rows?.find(row => row.study_iuid === studyInstanceUID);
        if (matchingRow) {
          setAlphabeticName(matchingRow.alphabetic_name?.replace(/\^/g, ' ') || '');
        } else {
          setAlphabeticName(null);
        }
      } catch (error) {
        console.error('Fetch error:', error);
        setAlphabeticName(null);
      }
    };
    fetchStudyData();
  }, [studyInstanceUID]);

  useEffect(() => {
    const fetchPacienteData = async () => {
      if (!studyInstanceUID) {
        setPacienteData(null);
        return;
      }
      try {
        const url = `https://app.laughing-grothendieck.74-208-159-220.plesk.page/paciente?uid=${studyInstanceUID}`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Error fetching paciente data');
        }
        const data = await response.json();
        if (data.rows && data.rows.length > 0) {
          setPacienteData(data.rows[0]);
        } else {
          setPacienteData(null);
        }
      } catch (error) {
        console.error('Fetch error:', error);
        setPacienteData(null);
      }
    };
    fetchPacienteData();
  }, [studyInstanceUID]);

  useEffect(() => {
    const fetchEstudiosData = async () => {
      if (!studyInstanceUID) {
        setEstudiosData([]);
        return;
      }
      try {
        const url = new URL('https://app.laughing-grothendieck.74-208-159-220.plesk.page/estudios');
        url.searchParams.set('uid', studyInstanceUID);
        if (pacienteID) {
          url.searchParams.set('paciente', pacienteID);
        }

        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Error fetching estudios data');
        }
        const data = await response.json();
        if (data.rows && data.rows.length > 0) {
          setEstudiosData(data.rows);
        } else {
          setEstudiosData([]);
        }
      } catch (error) {
        console.error('Fetch error:', error);
        setEstudiosData([]);
      }
    };
    fetchEstudiosData();
  }, [studyInstanceUID, pacienteID]);

  const handleTabClick = tab => {
    setSelectedTab(tab.label);
    if (tab.label === 'Estudios') {
      setIsModalOpen(true);
    } else if (tab.label === 'Imágenes') {
      setIsModalOpen(false);
    }
  };

  const buttonStyles = (isSelected, isHovered) => ({
    color: isHovered || isSelected ? '#3bd8dd' : '#f6f4e6',
    background: isHovered ? '#02468e' : isSelected ? '#00051e' : 'none',
    border: `1px solid ${isHovered || isSelected ? '#3bd8dd' : 'transparent'}`,
    cursor: 'pointer',
    padding: '7px 10px',
    marginRight: '5px',
    marginLeft: '5px',
    marginTop: '0px',
    borderRadius: '3px',
    transition: 'background-color 0.3s ease, color 0.3s ease',
  });

  return (
    <>
      {/* Estilos forzados */}
      <style>
        {`
          .scroll-desktop {
            overflow-y: scroll !important;
            scrollbar-color: #8e9da1 transparent !important;
            scrollbar-width: thin !important;
          }
          .no-scroll {
            overflow-y: hidden !important;
          }
        `}
      </style>

      <NavBar
        isSticky={isSticky}
        {...props}
      >
        <div className={isMobile ? '' : 'relative h-[48px] items-center'}>
          <div className="absolute left-0 flex items-center">
            {isReturnEnabled && (
              <button
                className="mr-3 cursor-pointer"
                onClick={onClickReturnButton}
                aria-label={t('Return')}
              >
                <Icon
                  name="chevron-left"
                  className="text-primary-active w-8"
                />
              </button>
            )}
            {isMobile ? <Icon name="logoDL" /> : <Svg name="logo-ohif" />}
          </div>
          <div
            className="flex items-center justify-center"
            style={{ marginLeft: '20px' }}
          >
            {tabs.map((tab, index) => (
              <button
                key={index}
                style={buttonStyles(selectedTab === tab.label, hoveredTab === tab.label)}
                onClick={() => handleTabClick(tab)}
                onMouseEnter={() => setHoveredTab(tab.label)}
                onMouseLeave={() => setHoveredTab(null)}
              >
                {tab.label}
              </button>
            ))}
            {apiData?.interpretacion ? (
              <a
                href={linkURL}
                target="_blank"
                rel="noopener noreferrer"
                style={buttonStyles(
                  selectedTab === 'Interpretación',
                  hoveredTab === 'Interpretación'
                )}
                onMouseEnter={() => setHoveredTab('Interpretación')}
                onMouseLeave={() => setHoveredTab(null)}
              >
                Interpretación
              </a>
            ) : (
              <div className="flex flex-col items-center">
                <span className="text-gray-500">Interpretación</span>
                <span className="text-gray-500">Pendiente</span>
              </div>
            )}
          </div>
          <div className="absolute right-0">{children}</div>
        </div>
      </NavBar>

      {isModalOpen && (
        <div
          className="bg-secondary-dark fixed inset-0 z-[1100] bg-opacity-75"
          style={{
            top: '48px',
            left: 0,
            width: '100%',
            height: 'calc(100% - 48px)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            boxSizing: 'border-box',
          }}
        >
          <div
            // Aplicamos clases según sea mobile o no
            className={`mt-0 w-full bg-black p-5 shadow-lg ${isMobile ? 'scroll-desktop' : 'scroll-desktop'}`}
            style={{
              height: '100%',
              marginTop: 0,
              padding: '20px',
              boxSizing: 'border-box',
            }}
          >
            <div className="mb-5">
              <p className="my-1.5 text-sm text-gray-400">
                {pacienteData?.ciudad || 'Ciudad no disponible'}
              </p>
              <h1 className="m-0 text-2xl text-white">
                {alphabeticName || 'Nombre no disponible'}
              </h1>
              {pacienteData && (
                <p className="my-1.5 text-sm text-gray-400">
                  {formatDate(pacienteData.fecha_nacimiento)} •
                  {pacienteData.edad ? ` ${pacienteData.edad} Años` : ''} •{' '}
                  {pacienteData.sexo === 'Hombre'
                    ? 'Masculino'
                    : pacienteData.sexo === 'Mujer'
                      ? 'Femenino'
                      : 'Otro'}
                </p>
              )}
            </div>

            {estudiosData.map((estudioItem, index) => (
              <div
                key={index}
                role="button"
                tabIndex={0}
                onClick={() => handleStudyClick(estudioItem.uid)}
                onKeyPress={e => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleStudyClick(estudioItem.uid);
                  }
                }}
                className="mb-2.5 flex cursor-pointer items-center justify-between rounded-lg border border-[#1a2b3c] bg-[#0d1b2a] p-2.5 hover:bg-[#1a2b3c]"
              >
                <div className="max-w-[90%]">
                  <h2 className="m-0 text-lg text-white">{estudioItem.estudio}</h2>
                  <p className="my-1.5 flex items-center text-sm text-gray-400">
                    <i className="fas fa-procedures mr-2"></i>
                    {estudioItem.sub_estudio ? `${estudioItem.sub_estudio}` : ''}
                  </p>
                  <p className="my-1.5 flex items-center text-sm text-gray-400">
                    <i className="fas fa-calendar-alt mr-2"></i>
                    {formatDate(estudioItem.fecha_registro)}
                  </p>
                </div>
                <button
                  name="compartirBTN"
                  onClick={e => {
                    e.stopPropagation();
                    handleShare(estudioItem.uid);
                  }}
                >
                  <Icon
                    name="compartir"
                    style={{ width: '30px', height: '30px' }}
                  />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

Header.propTypes = {
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    })
  ),
  children: PropTypes.node,
  isReturnEnabled: PropTypes.bool,
  isSticky: PropTypes.bool,
  onClickReturnButton: PropTypes.func,
  WhiteLabeling: PropTypes.object,
};

export default Header;
